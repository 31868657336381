<template>
  <a-drawer :visible="visible" title="接口权限配置" :destroyOnClose="true" placement="right" width="700" @close="close">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="快速添加">
        <a-list :loading="loading" item-layout="horizontal" :data-source="dataList">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a slot="actions" style="color: red">
              <a-popconfirm
                title="确认删除吗?"
                okText="确认"
                cancelText="取消"
                @confirm="() => handleDel(item.uri)"
              >
                <a-icon type="close" />
              </a-popconfirm>
            </a>
            <a-list-item-meta :description="item.uri">
              <span slot="title" :style="item.status ? '' : 'color: red'"><a-tag :color="item.status ? 'green' : 'red'">{{item.requestType || '未知'}}</a-tag>{{ item.name || '该接口未记录或已弃用' }}</span>
            </a-list-item-meta>
          </a-list-item>
          <div style="margin-top: 10px">
            <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              placeholder="请输入接口地址"
              :style="{ width: '100%' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
            />
            <a-tag v-else class="inter-item-add" @click="showInput">
              <a-icon type="plus"/> 新增
            </a-tag>
          </div>
        </a-list>
      </a-tab-pane>
      <a-tab-pane key="2" tab="接口勾选" force-render>
        <a-spin :spinning="loading">
          <a-tree
            v-model="checkedKeys"
            checkable
            :defaultExpandAll="true"
            :replaceFields="{children:'children', title:'name', key:'uri' }"
            :tree-data="treeData"
            @check="handleCheckTreeItem"
          />
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import {getMenuInterfaceConfig, addMenuInterfaceConfig, delMenuInterfaceConfig} from '@/api/menu'
export default {
  name: "InterfacePermission",
  data() {
    return {
      menuId: null,
      visible: false,
      loading: false,
      inputVisible: false,
      inputValue: '',
      dataList: [],
      checkedKeys: [],
      treeData: []
    }
  },
  methods: {
    open(menuId) {
      this.visible = true
      this.menuId = menuId
      this.getConfig()
    },
    getConfig() {
      if (!this.menuId) {
        return
      }
      this.dataList = []
      this.loading = true
      getMenuInterfaceConfig(this.menuId).then(res => {
        if (res.code == 200) {
          const treeData = res.data['interfaceTreeList']
          this.initTreeData(treeData)
          this.treeData = treeData
          const dataList = res.data['interfaceCheckedList']
          if (dataList != null && dataList.length > 0) {
            this.dataList = dataList
            let checkedKeys = []
            dataList.map(item => {
              if (item.status) {
                checkedKeys.push(item.uri)
              }
            })
            this.checkedKeys = checkedKeys
          } else {
            this.checkedKeys = []
          }
        }
      }).finally(()=> {
        this.loading = false
      })
    },
    getCheckedList() {
      this.dataList = []
      this.loading = true
      getMenuInterfaceConfig(this.menuId).then(res => {
        if (res.code == 200) {
          const dataList = res.data['interfaceCheckedList']
          if (dataList != null && dataList.length > 0) {
            this.dataList = dataList
            let checkedKeys = []
            dataList.map(item => {
              if (item.status) {
                checkedKeys.push(item.uri)
              }
            })
            this.checkedKeys = checkedKeys
          } else {
            this.checkedKeys = []
          }
        }
      }).finally(()=> {
        this.loading = false
      })
    },
    initTreeData(treeData) {
      if (treeData && treeData.length > 0) {
        treeData.forEach(node => {
          if (!node.dir) {
            node.name = `[${node.requestType}] ${node.name} [${node.uri}]`
          } else {
            node.checkable = false
          }
          this.initTreeData(node.children)
        })
      }
    },
    close() {
      this.visible = false
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(()=> {
        this.$refs['input'].focus()
      })

    },
    handleDel(uri) {
      this.loading = true
      delMenuInterfaceConfig({menuId: this.menuId, uri}).then(res => {
        if (res['code'] == 200) {
          this.getCheckedList()
        }
      }).finally(()=> {
        this.loading = false
      })
    },
    handleAdd(uri) {
      this.loading = true
      addMenuInterfaceConfig({menuId: this.menuId, uri}).then(res => {
        if (res['code'] == 200) {
          this.getCheckedList()
        }
      }).finally(()=> {
        this.loading = false
      })
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    handleInputConfirm() {
      this.inputVisible = false
      const v = this.inputValue.trim()
      if (v && v.length > 0) {
        this.handleAdd(v)
      }
      this.inputValue = ''
    },
    handleCheckTreeItem(checkedKeys , {checked, node}) {
      if (checked) {
        this.handleAdd(node.$vnode.key)
      } else {
        this.handleDel(node.$vnode.key)
      }
    },
  }
}
</script>

<style scoped lang="less">
.inter-list-item {
  display: flex;
  justify-content: flex-start;
  padding-right: 80px;
}
.inter-item-add {
  background: #fff;
  border: 1px dashed #bbb;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  height: 32px;
  line-height: 32px
}
</style>