<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="菜单来源">
                <a-radio-group v-model="queryParam.menuFrom" @change="getMenuList" button-style="solid">
                  <a-radio-button v-for="item in menuFromOptions" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </a-radio-button>
                </a-radio-group>

                <!--                <a-select v-model="queryParam.menuFrom" placeholder="请选择" allowClear @change="getMenuList">-->
                <!--                  <a-select-option-->
                <!--                    v-for="item of menuFromOptions"-->
                <!--                    :key="item.value"-->
                <!--                    :value="item.value"-->
                <!--                  >-->
                <!--                    {{ item.label }}-->
                <!--                  </a-select-option>-->
                <!--                </a-select>-->
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="菜单名称">
                <a-input v-model="queryParam.menuName" placeholder="请输入菜单名称" allowClear @pressEnter="getMenuList"/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="使用状态">
                <a-select v-model="queryParam.status" placeholder="请选择" allowClear>
                  <a-select-option
                    v-for="item of statusOptions"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-button type="primary" @click="getMenuList">查询</a-button>
              <a-button style="margin-left: 8px">重置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd" v-permission="['/menu/add']">新建</a-button>
      </div>
      <a-table :columns="columns" :data-source="menuTreeData">
        <template slot="operation" slot-scope="text, record">
          <a @click="() => onAddNode(record.keyId)" v-permission="['/menu/add']">添加</a>
          <a-divider type="vertical" v-permission="['/menu/edit']"/>
          <a @click="() => onEdit(record.keyId)" v-permission="['/menu/edit']">修改</a>
          <a-divider type="vertical" v-permission="['/menu/del']"/>
          <a-popconfirm
            title="确认删除吗?"
            @confirm="() => onDelete(record.keyId)"
          >
            <a href="javascript:;" v-permission="['/menu/del']">删除</a>
          </a-popconfirm>
          <a-divider type="vertical" v-permission="['/menu/del']"/>
          <a @click="() => onInterfacePermission(record.keyId)" v-permission="['/menu/edit']">接口权限</a>
        </template>
      </a-table>
      <create-form
        ref="createModal"
        :visible="visible"
        :model="mdl"
        :parentList="menuTreeData"
        @cancel="handleCancel"
        @ok="handleOk"
      />
      <interface-permission ref="interface_permission"/>
    </a-card>
  </div>
</template>

<script>
import CreateForm from './components/CreateForm'
import InterfacePermission from "@/views/system/menu/components/InterfacePermission";
import { menuList, menuDel, menuInfo } from '@/api/menu'
// import moment from 'moment'

const columns = [
  {
    title: '菜单名称',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: '菜单类型',
    dataIndex: 'menuTypeName',
    width: 90
  },
  {
    title: '权限名称',
    dataIndex: 'permsName',
    width: 150
  },
  {
    title: '权限标识',
    dataIndex: 'perms'
  },
  // {
  //   title: '组件路径',
  //   dataIndex: 'component'
  // },
  {
    title: '是否外链',
    dataIndex: 'showIsFrame',
    width: 90
  },
  {
    title: '菜单显示',
    dataIndex: 'showStatusName',
    width: 90
  },
  {
    title: '状态',
    dataIndex: 'statusName',
    width: 80
  },
  {
    title: '排序',
    dataIndex: 'sort',
    width: 90
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  components: {
    CreateForm,
    InterfacePermission
  },
  data () {
    return {
      columns,
      queryParam: {
        menuFrom: 2
      },
      // 菜单状态数据字典
      statusOptions: [
        { value: 0, label: '正常' },
        { value: 1, label: '停用' }
      ],
      menuFromOptions: [
        { value: 1, label: 'PC管理端' },
        { value: 2, label: '小程序端' }
      ],
      menuTypeNameMap: {
        1: '目录',
        2: '菜单',
        3: '按钮'
      },
      menuTreeData: [],
      visible: false,
      mdl: null,
      menuInfoData: {}
    }
  },
  provide () {
    return {
      queryParam: this.queryParam,
      getMenuList: this.getMenuList
    }
  },
  created () {
    this.getMenuList()
  },
  methods: {
    async getMenuList () {
      try {
        const res = await menuList(this.queryParam)
        if (res.code === 200) {
          const tree = JSON.parse(JSON.stringify(res.data))
          const treeRename = (list) => {
            list.map(item => {
              item.statusName = item.status === 0 ? '正常' : '停用'
              item.showIsFrame = item.isFrame === 1 ? '是' : '否'
              item.showStatusName = item.showStatus === 1 ? '显示' : '隐藏'
              item.keyId = JSON.parse(JSON.stringify(item.key))
              item.menuTypeName = this.menuTypeNameMap[item.menuType]
              // item.key = item.routerPath + item.keyId + item.name
              // item.createTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
              if (item.children) {
                treeRename(item.children)
              }
              return item
            })
          }
          treeRename(tree)
          this.menuTreeData = tree
        }
      } catch (e) {
        console.error(e)
      }
    },
    handleAdd () {
      this.mdl = {
        menuFrom: this.queryParam.menuFrom
      }
      console.log('this.mdl', this.mdl)
      this.visible = true
    },
    handleCancel () {
      this.visible = false
    },
    handleOk () {
      this.visible = false
      this.getMenuList()
    },
    async onDelete (parentId) {
      try {
        const res = await menuDel({ menuId: parentId })
        if (res.code === 200) {
          this.getMenuList()
          this.$message.success('删除成功')
        }
      } catch (e) {
        console.error(e)
      }
    },
    async onEdit (parentId) {
      try {
        const res = await menuInfo({ menuId: parentId })
        if (res.code === 200) {
          console.log('res.data', res.data)
          this.mdl = res.data
          this.visible = true
        }
      } catch (e) {
        console.error(e)
      }
    },
    onAddNode (parentId) {
      this.mdl = {
        parentId,
        menuFrom: this.queryParam.menuFrom
      }
      this.visible = true
    },
    onInterfacePermission(menuId) {
      this.$nextTick(()=> {
        this.$refs['interface_permission'].open(menuId)
      })
    }
  }
}
</script>
